import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfigurationBreadcrumbs/ConfigurationBreadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ConfigurationHeading.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/DevTools.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/apps/web/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/HelpMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/web/src/components/Providers/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/RegisterForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/ReportTabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/WorkflowLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/WorkflowMenu/WorkflowMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/YearMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-date-picker@11.0.0_@types+react-dom@18.3.1_@types+react@18.3.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-date-picker/dist/DatePicker.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-calendar@5.1.0_@types+react@18.3.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-calendar/dist/Calendar.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-toastify@10.0.6_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/main.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/PwCHelveticaNeue/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/PwCHelveticaNeue/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/PwCHelveticaNeue/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"pwcHelveticaNeue\"}");
